<template>
  <v-card :loading="loading" elevation="0">
    <div>
      <v-text-field
        v-model="filter"
        prepend-icon="mdi-account-search"
        label="Поиск"
        color="teal lighten-1"
      ></v-text-field>

      <v-card
        v-for="client in filteredClients(filter)"
        :key="client._id"
        class="pl10 mt10 mx-1 elevation-5 rounded"
        :dark="getBlackPhones().includes(client.phone)"
        @click="setFIT(client)"
      >
        <div>
          <v-card-text>
            {{ client.lastName }}
            {{ client.firstName }}
            <br>
            {{ client.phone }}
          </v-card-text>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: "ClientList",

    props: ["setFIT"],

    data: () => ({
      filter: "",
      clientList: [],
      blackList: [],
      loading: false
    }),

    methods: {
      async loadClientList() {
        this.loading = true
        const json = await this.authFetch('/subscriber/load-subscriber-list', {})
        if (json.status === 200) {
          this.clientList = json.subscribers
          this.clientList.sort(this.sortClientListFunc)
        }

        this.loading = false
      },

      async loadBlackList() {
        this.loading = true
        const json = await this.authFetch('/black/load-black-list', {})
        this.blackList = json.blackList
        this.loading = false
      },

      getBlackPhones() {
        if (!this.blackList.length) {
          return []
        }

        return this.blackList.map(b => b.phone)
      },

      filteredClients(str) {
        if (!this.clientList.length) return []
        if (!this.filter) return this.clientList

        const filtered = this.clientList
          .map(c => JSON.stringify({firstName: c.firstName, lastName: c.lastName, phone: c.phone}))
          .filter(j => j.toLowerCase().includes(str.toLowerCase()))
          .map(j => JSON.parse(j))

        return this.clientList.filter(c => filtered.map(f => f.phone).includes(c.phone))
      },
    },

    async mounted() {
      await this.loadClientList()
      await this.loadBlackList()
    }
  }

</script>

<style scoped>

</style>
