<template>
  <v-container>
    <v-row justify="center">
      <v-card
        :loading="!this.$store.getters.ownerId.length"
        class="mx-auto"
        width="374"
        elevation="0"
      >
        <v-card>
          <v-card-title>Вы записываете клиента на:</v-card-title>
          <v-card-text class="teal--text text--lighten-1">
            <h3>{{ this.$store.getters.date | date }} {{ this.$store.getters.time }}</h3>
            <br>
            <b>Услуга:</b> {{ this.$store.getters.category.title }}
            <br>
            <b>Процедуры:</b> {{ this.$store.getters.services.map(s => s.title).join(", ") }}
            <br>
            <b>Предварительный расчет стоимости:</b> {{ this.$store.getters.total }} руб
          </v-card-text>
        </v-card>

        <v-row justify="center" class="mt-10">
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="teal lighten-1"
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-magnify
                </v-icon>
                Выбрать из списка
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="teal--text text--lighten-1">Выберите клиента</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px;">
                <ClientList
                  :setFIT="setFIT"
                />
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="teal lighten-1"
                  text
                  @click="dialog = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <v-form v-model="valid" ref="form" @submit.prevent="submitHandler">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="firstName"
                  :rules="firstRules"
                  color="teal"
                  label="Имя"
                  required
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="lastName"
                  :rules="lastRules"
                  color="teal"
                  label="Фамилия"
                  required
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  color="teal"
                  label="Номер телефона"
                  required
                  persistent-hint
                  prepend-icon="mdi-phone"
                  @input="attention = true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="comment"
                  color="teal"
                  label="Комментарий"
                  prepend-icon="mdi-comment-processing"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="start">
              <v-card-text class="error--text" v-if="attention">
                Так как номер телефона в данной форме не проходит верификацию через код (звонок / смс),
                следует отнестись к заполненеию поля "Номер телефона" особенно внимательно.
              </v-card-text>
              <v-btn color="teal" type="submit" text>
                Записать
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from "vuex"
  import phoneFilter from "../../filters/phoneFilter"
  import ClientList from "../../components/ClientList";

  export default {
    name: "Introduce",
    components: {ClientList},
    data: () => ({
      firstName: "",
      firstRules: [
        v => !!v.length || "Заполните это поле",
      ],
      lastName: "",
      lastRules: [
        v => !!v.length || "Заполните это поле",
      ],
      phone: "",
      phoneRules: [
        v => !!v.length || "Заполните это поле",
        v => v.length === 12 || "Номер должен быть в формате +79*********"
      ],
      comment: "",
      valid: true,
      dialog: false,
      attention: true,
    }),

    computed: {
      ...mapGetters(["ownerId"])
    },

    methods: {
      validatePhoneNumber() {
        this.phone = phoneFilter(this.phone)
      },

      reset() {
        this.$refs.form.reset()
      },

      async submitHandler() {
        this.$refs.form.validate()
        if (!this.valid) return
        this.$store.commit("setFirstName", this.firstName.trim())
        this.$store.commit("setLastName", this.lastName.trim())
        this.$store.commit("setPhone", this.phone)
        const comment = this.comment
        const category = this.$store.state.currentRecord.category._id
        const services = this.$store.state.currentRecord.services.map(service => service._id)
        const verify = await this.authFetch(
          '/black/black-list-verify',
          {phone: this.$store.getters.phone, owner: this.$store.getters.ownerId},
          false)
        if (verify.status === 400) {
          this.$store.commit('setServerMessage', 'номер в черном списке')
          if (!window.confirm(`Номер ${this.$store.getters.phone} у Вас в черном списке, Вы уверены что хотите продолжить?`)) {
            return
          }
        }

        const json = await this.authFetch('/record/record-client-master', {
          firstName: this.$store.getters.firstName,
          lastName: this.$store.getters.lastName,
          phone: this.$store.getters.phone,
          comment,
          date: this.$store.getters.date,
          time: this.$store.getters.time,
          category,
          services,
          total: this.$store.getters.total,
          open: this.$store.getters.open,
          owner: this.$store.getters.ownerId
        })

        if (json.status === 200) {
          this.$store.commit("setTime", "")
          this.$store.commit("setPhone", "")
          this.$store.commit("setLastName", "")
          this.$store.commit("setFirstName", "")
          this.$store.commit("setTotal", 0)
          this.$store.commit("setServices", [])
          this.$store.commit("setCategory", "")
          await this.$router.push(`/calendar?date=${this.$store.getters.date}`)
          this.$store.commit("setDate", "")
        }
      },

      setFIT(client) {
        this.firstName = client.firstName
        this.lastName = client.lastName
        this.phone = client.phone
        this.dialog = false
        this.attention = false
      }
    },

    async mounted() {
      if (
        !this.$store.getters.category ||
        !this.$store.getters.services.length ||
        !this.$store.getters.date.length ||
        !this.$store.getters.time.length
      ) {
        await this.$router.push("/")
      }
      this.firstName = this.$store.getters.firstName
      this.lastName = this.$store.getters.lastName
      this.phone = this.$store.getters.phone
      if (this.firstName && this.lastName && this.phone) {
        this.attention = false
      }
    },

    watch: {
      phone: function () {
        this.phone = phoneFilter(this.phone)
      }
    }
  }
</script>

<style scoped>

</style>
